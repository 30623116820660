<template>
  <ColorsList />
</template>

<script>
  export default {
    components: {
      ColorsList: () => import('@/components/stock/colors/ColorsList')
    }
  }
</script>